import { ITransactionType } from 'shared/types/transactionTypes';
import {
  BILL_ID,
  EDUCATION_ID,
  ENTERTAINMENT_ID,
  FOOD_ID,
  ICategory,
  ICONS_FA,
  ICONS_GI,
  ICONS_MD,
  INSURANCE_ID,
  INVESTMENT_ID,
  SHOPPING_ID,
  TRANSPORTATION_ID,
} from './categories';

export const SUB_CATEGORIES: ICategory[] = [
  {
    mainCategoryId: BILL_ID,
    uuid: '4fea3090-ca76-49a3-8420-77b5a4cc7cfe',
    label: 'Phone bill',
    iconName: 'MdPhone',
    iconLib: ICONS_MD,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: BILL_ID,
    uuid: 'ae63bf43-c526-4483-9d3e-d5496d3141f5',
    label: 'Internet bill',
    iconName: 'FaWifi',
    iconLib: ICONS_FA,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: EDUCATION_ID,
    uuid: 'ed31e612-90dc-4cd6-8543-feb321926e64',
    label: 'Online Course',
    iconName: 'FaLaptopCode',
    iconLib: ICONS_FA,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: FOOD_ID,
    uuid: '8cc3c2a2-8d1a-4009-b1c1-e9d432356b5f',
    label: 'Food delivery',
    iconName: 'MdDeliveryDining',
    iconLib: ICONS_MD,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: INVESTMENT_ID,
    uuid: 'bd82c574-af47-44bb-9307-e06142ad7da4',
    label: 'Condominium',
    iconName: 'FaRegBuilding',
    iconLib: ICONS_FA,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: INSURANCE_ID,
    uuid: '071a3066-0b39-46bf-9244-7e7e03161a31',
    label: 'Life Insurance',
    iconName: 'MdOutlineHealthAndSafety',
    iconLib: ICONS_MD,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: ENTERTAINMENT_ID,
    uuid: '16ef4695-52a0-4209-b201-fccee29c7f51',
    label: 'Streaming Service',
    iconName: 'BiMoviePlay',
    iconLib: 'react-icons/bi',
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: TRANSPORTATION_ID,
    uuid: '17b69446-78b9-4271-bd07-9698d101eb0a',
    label: 'Maintenance',
    iconName: 'FaTools',
    iconLib: ICONS_FA,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: TRANSPORTATION_ID,
    uuid: 'b7034797-484a-4775-93f4-7a158d89f0f4',
    label: 'Petrol',
    iconName: 'FaGasPump',
    iconLib: ICONS_FA,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: TRANSPORTATION_ID,
    uuid: '3068089e-7431-41c5-b277-ec9aa123c040',
    label: 'Parking Fees',
    iconName: 'FaParking',
    iconLib: ICONS_FA,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: TRANSPORTATION_ID,
    uuid: 'ac37aa87-c373-4b74-8374-195c51e27568',
    label: 'Taxi',
    iconName: 'FaTaxi',
    iconLib: ICONS_FA,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: FOOD_ID,
    uuid: 'f60d2ded-3f9c-47f1-a44f-a6a7e5163314',
    label: 'Grocery',
    iconName: 'MdLocalGroceryStore',
    iconLib: ICONS_MD,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: FOOD_ID,
    uuid: '77853c90-90f2-4d08-966c-8bccb0aa9b99',
    label: 'Restaurants',
    iconName: 'MdRestaurant',
    iconLib: ICONS_MD,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: SHOPPING_ID,
    uuid: '91f077eb-ce1d-43f9-89a6-db9d920657b4',
    label: 'Electronics',
    iconName: 'MdOutlinePhoneIphone',
    iconLib: ICONS_MD,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: SHOPPING_ID,
    uuid: '7a38fb58-e9b7-47af-b1a7-0cef632d597e',
    label: 'Accessories',
    iconName: 'GiHeartNecklace',
    iconLib: ICONS_GI,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: SHOPPING_ID,
    uuid: 'd7a45181-d211-4025-9137-4f0d3e4b5b13',
    label: 'Appliances',
    iconName: 'CgSmartHomeRefrigerator',
    iconLib: 'react-icons/cg',
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: SHOPPING_ID,
    uuid: '8fb59686-d3fc-43e9-9492-1858d671bd07',
    label: 'Tools',
    iconName: 'AiTwotoneTool',
    iconLib: 'react-icons/ai',
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: SHOPPING_ID,
    uuid: '7e6aa3b2-4b50-41aa-8c68-5ba30de894ca',
    label: 'Footwear',
    iconName: 'GiConverseShoe',
    iconLib: ICONS_GI,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: SHOPPING_ID,
    uuid: '687ed341-b1f2-4280-8c69-1f0e25d0349b',
    label: 'Clothing',
    iconName: 'IoShirtSharp',
    iconLib: 'react-icons/io5',
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: SHOPPING_ID,
    uuid: 'f05f957d-52a6-44a1-9b95-0443bcc558ae',
    label: 'Skincare',
    iconName: 'FaPumpSoap',
    iconLib: ICONS_FA,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
  {
    mainCategoryId: SHOPPING_ID,
    uuid: 'e7b354b0-9192-4068-a44c-038560fc34c8',
    label: 'Makeup',
    iconName: 'GiLipstick',
    iconLib: ICONS_GI,
    createdAt: new Date().getTime(),
    type: ITransactionType.Expense,
  },
];
